import styled from "@emotion/styled"
import theme from "../../styles/theme"

const SidewaysContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

	@media(min-width:${theme.breakpoints.small}) {
		  flex-direction: row;
	}
`
export default SidewaysContainer
