import styled from "@emotion/styled"
import theme from "../../styles/theme"

const Wrapper = styled.div`
  background-color: ${theme.colors.black};
  padding: 0 24px;
  display: flex;
  flex-direction: column;

	@media(min-width:${theme.breakpoints.navMobile}) {
    min-width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
	}
`
export default Wrapper
