import styled from '@emotion/styled'
import theme from '../../styles/theme'

const H1 = styled.h1`
  font-size: 10vh;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.header};
  font-weight: normal;
`
export default H1
