import styled from "@emotion/styled"
import theme from "../../styles/theme"

const HeaderContainer = styled.div`
    width: 100%;
    margin-top: 10rem;
    margin-bottom: 6rem;

	@media(min-width:${theme.breakpoints.navMobile}) {
        margin-left: 170px;
        max-width: 35vw;
        min-width: 450px;
        padding-right: 20vh;
        margin-top: 0;
        margin-bottom: 0;
	}
`
export default HeaderContainer
