import styled from "@emotion/styled"
import theme from "../../styles/theme"

const Container = styled.div`
  margin: 0;
  padding: 0;
  background: ${theme.colors.black};


	@media(min-width:${theme.breakpoints.navMobile}) {
    height: 100vh;
    display: flex;
	}
`
export default Container
